<div class="container-fluid" *ngIf="audioFileName">
  <div *transloco="let t; prefix: 'audioComponent'">
    <div class="row d-flex anime-clean contentArea audioContainer">
      <div>{{ t('audioText') }}:</div>
      <ul>
        <li>
          <button id="toggleMuteBtn" type="button" attr.aria-label="{{ audioAriaLabel }}" class="audio-btn" (click)="toggleMute()">
            <img [src]="audioImg" alt="" />
          </button>
        </li>
        <li>
          <button type="button" attr.aria-label="{{ t('audioReplayButtonAriaLabel') }}" class="audio-btn" (click)="replay()">
            <img src="assets/images/replay.svg" alt="" />
          </button>
        </li>
        <li>
          <a
            href="{{ audioTrancriptFileName }}"
            download
            id="downloadAudioTranscript"
            type="button"
            attr.aria-label="{{ t('downloadAudioTranscriptButtonAriaLabel') }}"
            class="audio-btn"
          >
            <img src="assets/images/downloadTranscript.svg" width="24px" alt="" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<audio id="amznAudio" #amznAudio [src]="audioFileName"></audio>
