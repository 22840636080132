<header *transloco="let t; prefix: 'headerComponent'">
  <div class="row mainHeaderContent">
    <div class="col-6">
      <div class="float-left logoHeader">
        <img alt="{{ t('amazonLogoAltText') }}" class="amzn-logo" />
      </div>
    </div>
    <div class="col-6">
      <div class="helpContentArea">
        <select
          *ngIf="showTranslationDropdown"
          class="translationDropdown m-0 mb-3 mx-3"
          attr.aria-label="{{ t('translationLanguageDropdown') }}"
          id="translation-language"
          (change)="onTranslationLanguageChange($event.target.value)"
        >
          <option *ngFor="let opt of translationOptions" [value]="opt.value" [selected]="opt.value === currentLanguageSetting.value">
            {{ opt.label }}
          </option>
        </select>
        <button class="help-btn float-right" (click)="openHelpModal()"><i class="icon-help"></i>{{ t('getHelpButton') }}</button>
      </div>
    </div>
  </div>
</header>

<!-- Get Help Modal Popup Start -->
<div *transloco="let t; prefix: 'headerComponent'" class="modal gethelpModal" id="help-modal" tabindex="-1" role="dialog" aria-labelledby="getHelpModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">{{ t('getHelpModalTitle') }}</h2>
        <div class="modal-title disclosure-subtitle">{{ t('seeIfHelps') }}</div>
      </div>
      <div class="modal-body">
        <div class="help-modal-content" [innerHTML]="faq"></div>
        <div class="help-modal-content">
          <h3>{{ t('contactHelpInstruction1') }}</h3>
          <p>{{ t('contactHelpInstruction2') }}</p>
        </div>
      </div>
      <div id="getHelpModal" hidden>{{ t('getHelpModalAriaLabel') }}</div>
      <div class="modal-footer">
        <div class="row btnAlign">
          <div class="col-12 mx-auto">
            <button type="button" class="btn button btnActive" (click)="closeHelpModal()">{{ t('foundMyAnswerButton') }}</button>
          </div>
          <div class="col-12 mt-4 mx-auto">
            <button [disabled]="disableHelpRequestButton" type="button" class="btn button" (click)="helpRequest()">{{ t('requestHelpButton') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Get Help Modal Popup End -->

<!-- Get Help Modal Popup Start -->
<div
  *transloco="let t; prefix: 'headerComponent'"
  class="modal gethelpModal"
  id="help-request-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="helpRequestedModal"
>
  <div class="modal-dialog" role="document">
    <div id="helpRequestedModal" hidden>{{ t('helpRequestedModalAriaLabel') }}</div>
    <div class="modal-content">
      <div class="modal-header"></div>
      <div class="modal-body text-center">
        <h2 class="mt-5">{{ t('helpRequestedInstruction1') }}</h2>
        <p>{{ t('helpRequestedInstruction2') }}</p>
      </div>
      <div class="modal-footer">
        <div class="row btnAlign">
          <div class="col-12 mx-auto">
            <button type="button" class="btn button btnActive" (click)="cancelHelpRequest()">{{ t('cancelRequestButton') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Error Modal Popup Start -->
<div *transloco="let t; prefix: 'headerComponent'" class="modal getErrorModal" id="error-modal" tabindex="-1" role="dialog" aria-labelledby="errorModal">
  <div class="modal-dialog" role="document">
    <div id="errorModal" hidden>{{ t('errorModalAriaLabel') }}</div>
    <div class="modal-content">
      <div class="modal-header"></div>
      <div class="modal-body text-center">
        <h2 class="mt-5">{{ t('errorInstruction') }}</h2>
        <p id="errorContent">{{ errorMsg }}</p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-12 mx-auto">
            <button type="button" class="btn button btnActive" (click)="closeErrorPopup()">{{ t('closeButton') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Error Modal Popup Start -->
<div
  *transloco="let t; prefix: 'headerComponent'"
  class="modal getPleaseWaitModal"
  id="please-wait-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PleaseWaitModal"
>
  <div class="modal-dialog" role="document">
    <div id="PleaseWaitModal" hidden>{{ t('pleaseWaitModalAriaLabel') }}</div>
    <div class="modal-content">
      <div class="modal-header"></div>
      <div class="modal-body text-center"></div>
    </div>
    <div class="loader-wrap" id="main-spinner">
      <div class="loader"></div>
    </div>
  </div>
</div>

<!-- Get Help Modal Popup End -->
